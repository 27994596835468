import { Country } from '../declarations/auth-api';

export const setRegistrationCountries = (countries: Country[]) => ({
  type: 'SET_REGISTRATION_COUNTRIES',
  countries,
});

const registrationCountries = (state: Country[] = [], action): Country[] => {
  switch (action.type) {
    case 'SET_REGISTRATION_COUNTRIES': {
      return action.countries ?? [];
    }
    default: {
      return state ?? [];
    }
  }
};

export default registrationCountries;
