import { FC, useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import cfi from 'country-flag-icons/react/3x2';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import lowerCase from 'lodash/lowerCase';
import { useAppTranslation } from '../../i18n';
import makeCall from '../../helpers/caller';
import { log } from '../../app-config';
import { Country, PanelLanguage } from '../../declarations/auth-api';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectCountry } from '../../reducers/country';
import { selectLanguage } from '../../reducers/language';
import { getQueryObj, setQueryObj } from '../../helpers/helpers';

const flagStyle = { width: '30px', height: '20px' } as const;

const getFlag = (code: string, name?: string): JSX.Element => {
  const title = name || code;
  // special cases
  switch (code.toLowerCase()) {
    case 'en':
    case 'en-uk':
    case 'gb':
      return <cfi.GB title={title} style={flagStyle} />;
    case 'au':
    case 'en-au':
      return <cfi.AU title={title} style={flagStyle} />;
    case 'us':
    case 'en-us':
      return <cfi.US title={title} style={flagStyle} />;
    case 'el':
      return <cfi.GR title={title} style={flagStyle} />;
  }
  // other English variations
  if (code.toLowerCase().startsWith('en')) {
    return <cfi.GB title={title} style={flagStyle} />;
  }
  // as a fallback, just a flag
  const FlagComponent: cfi.FlagComponent | null = cfi[code];
  if (FlagComponent) {
    return FlagComponent({ title, style: flagStyle });
  }
  // if code not found in the lib, return EU flag
  return <cfi.EU title={title} style={flagStyle} />;
};

interface PickerOption {
  value: string;
  label: string;
  country: Country;
  language: PanelLanguage;
}

export const LanguageFlagPicker: FC = (props) => {
  const inviteUuidMatch = window.location.pathname.match(/^\/join\/invite\/([-a-z0-9]+)$/i);
  const inviteUuid = inviteUuidMatch && inviteUuidMatch[1] ? inviteUuidMatch[1] : undefined;

  const dispatch = useAppDispatch();
  const { i18n } = useAppTranslation('languages');
  const [options, setOptions] = useState<PickerOption[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const country = useAppSelector((s) => s.country);
  const language = useAppSelector((s) => s.language);
  const selectedOption =
    options.find(
      (o) => o.country.country_code === country?.country_code && o.language.language_code === language?.language_code
    )?.value ?? '';

  useEffect(() => {
    makeCall<Country[]>({
      call: {
        section: 'auth',
        job: 'countriesList',
      },
      queryParams: {
        from: 'header',
        inviteUuid,
      },
    })
      .then((countries) => {
        const arr: PickerOption[] = [];
        for (const c of countries) {
          if (c.panel_languages) {
            for (const l of c.panel_languages) {
              let label;
              if (c.panel_languages.length > 1) {
                label = `${c.country_name} - ${l.language_name}`;
              } else {
                label = `${c.country_name}`;
              }
              arr.push({
                value: `${c.country_code}_${l.language_code}`,
                label,
                country: c,
                language: l,
              });
            }
          }
        }
        setOptions(arr);
        setLoaded(true);
      })
      .catch((err) => {
        log.error(err);
      });
  }, [inviteUuid]);

  useEffect(() => {
    if (!loaded) {
      return;
    }

    let c: Country | undefined;
    const query = getQueryObj();
    if (query.country_code) {
      c = options.find((o) => lowerCase(o.country.country_code) === lowerCase(query.country_code!))?.country;
      delete query.country_code;
      setQueryObj(query);
    }
    const currentCountry = country;
    if (!c && currentCountry) {
      c = options.find((o) => o.country.country_code === currentCountry.country_code)?.country;
    }
    if (!c) {
      const optionsByGeo = options.filter((o) => o.country.geo_ip);
      if (optionsByGeo && optionsByGeo.length > 0) {
        c = optionsByGeo[0].country;
      }
    }
    if (!c) {
      c = options[0].country;
    }
    dispatch(selectCountry(c ?? null));

    let l: PanelLanguage | null = null;
    if (query.language_code) {
      l = c?.panel_languages?.find((pl) => lowerCase(pl.language_code) === lowerCase(query.language_code!)) ?? null;
      delete query.language_code;
      setQueryObj(query);
    }
    const currentLanguageCode = language?.language_code ?? i18n.language;
    if (!l && c.panel_languages && c.panel_languages.find((pl) => pl.language_code === currentLanguageCode)) {
      // we can keep current language
      l = c.panel_languages.find((pl) => pl.language_code === currentLanguageCode)!;
    } else if (!l && c.panel_languages && c.panel_languages.length > 0) {
      // we need to set default language for current country
      l = c.panel_languages.find((pl) => pl.is_default) ?? c.panel_languages[0];
    }
    dispatch(selectLanguage(l ?? null));
  }, [dispatch, loaded, options, country, language, i18n]);

  const onChange = useCallback(
    (e: SelectChangeEvent) => {
      const option = options.find((o) => o.value === e.target.value)!;
      dispatch(selectCountry(option.country));
      dispatch(selectLanguage(option.language));
    },
    [dispatch, options]
  );

  useEffect(() => {
    if (options?.length > 0 && country?.isLocked) {
      const newOptions = options.filter((o) => o.country.country_code === country.country_code);
      if (!isEqual(options, newOptions)) {
        setOptions(newOptions);
      }
    }
  }, [options, country]);

  if (options.length <= 1) {
    return null;
  }
  const nonEnglish = options.map((o) => o.language.language_code).filter((l) => !l.startsWith('en'));
  if (nonEnglish.length === 0) {
    return null;
  }
  return (
    <Select
      id="select-language"
      variant="standard"
      renderValue={(value) => {
        const option = options.find((o) => o.value === value);
        return option ? getFlag(option.country.country_code, option.label) : null;
      }}
      value={selectedOption}
      onChange={onChange}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {getFlag(option.country.country_code, option.label)}&nbsp;{option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
